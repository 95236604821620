import { Evented } from 'leaflet'

export const PGEvent = Evented.extend({})

PGEvent.addListener = function (target, type, fn) {
  // Aggiungi il listener all'oggetto target
  if (type === 'zoombefore') {
    type = 'zoomstart'
    target.on(type, fn)
  } else if (type === 'zoomafter') {
    type = 'zoomend'
    target.on(type, fn)
  } else if (target instanceof PGMappy && (type === 'mouseupmapbefore' || type === 'mouseupmapafter' || type === 'mouseup')) {
    target.on('mouseup', function (e) {
      // Attiva l'evento "mouseupmapbefore"
      target.fire('mouseupmapbefore')
      // Esegui la funzione fn su mouseup
      fn(e)
      // Attiva l'evento "mouseupmapafter"
      target.fire('mouseupmapafter')
    })
  } else {
    target.on(type, fn)
  }
}

// PGEvent disponibile a livello globale per compatibilita vecchie API
window.PGEvent = PGEvent
