// TIPO MAPPE: 1 MAPPA, 2 ORTOFOTO, 3 MAP+TRAFFICO
const PGMAP_TYPE_MAP = 1
const PGMAP_TYPE_ORTO = 2
const PGMAP_TYPE_TRAFFIC = 3

// Non più usato
const PGMAP_IMG_TYPE_LIGHT = 1

const boundsArrayZ18 = [
  {
    city: 'palermo',
    southWest: { lat: 38.052818, lng: 13.239721 },
    northEast: { lat: 38.227123, lng: 13.460422 }
  },
  {
    city: 'napoli',
    southWest: { lat: 40.790255, lng: 14.127744 },
    northEast: { lat: 40.921094, lng: 14.356175 }
  },
  {
    city: 'roma',
    southWest: { lat: 41.648857, lng: 12.225248 },
    northEast: { lat: 42.106668, lng: 12.857263 }
  },
  {
    city: 'firenze',
    southWest: { lat: 43.728345, lng: 11.147756 },
    northEast: { lat: 43.838108, lng: 11.339952 }
  },
  {
    city: 'pisa',
    southWest: { lat: 43.582474, lng: 10.26749 },
    northEast: { lat: 43.751211, lng: 10.438307 }
  },
  {
    city: 'bologna',
    southWest: { lat: 44.418602, lng: 11.22559 },
    northEast: { lat: 44.558825, lng: 11.421476 }
  },
  {
    city: 'venezia',
    southWest: { lat: 45.228948, lng: 12.152387 },
    northEast: { lat: 45.589057, lng: 12.581304 }
  },
  {
    city: 'padova',
    southWest: { lat: 45.348796, lng: 11.80394 },
    northEast: { lat: 45.458577, lng: 11.959782 }
  },
  {
    city: 'verona',
    southWest: { lat: 45.35921, lng: 10.873612 },
    northEast: { lat: 45.54515, lng: 11.116805 }
  },
  {
    city: 'monza',
    southWest: { lat: 45.556359, lng: 9.226909 },
    northEast: { lat: 45.632358, lng: 9.313644 }
  },
  {
    city: 'milano',
    southWest: { lat: 45.399193, lng: 9.040675 },
    northEast: { lat: 45.535958, lng: 9.278047 }
  },
  {
    city: 'genova',
    southWest: { lat: 44.382267, lng: 8.665256 },
    northEast: { lat: 44.519214, lng: 9.089091 }
  },
  {
    city: 'torino',
    southWest: { lat: 45.017648, lng: 7.575495 },
    northEast: { lat: 45.141136, lng: 7.770832 }
  }
]

export const global = {
  PGMAP_TYPE_MAP,
  PGMAP_TYPE_ORTO,
  PGMAP_TYPE_TRAFFIC,
  PGMAP_IMG_TYPE_LIGHT,
  boundsArrayZ18
}
