import { Map } from '../../node_modules/leaflet/src/map/Map'
import { Control, DomEvent, DomUtil } from 'leaflet'

export const CloseMap = Control.extend({
  options: {
    // @option position: String = 'topright'
    // The position of the control (one of the map corners). Possible values are `'topleft'`,
    // `'topright'`, `'bottomleft'` or `'bottomright'`
    position: 'topright',
    // @option title: String = 'Chiudi'
    // The title tag of the control
    title: 'Chiudi',
    // @option closeCallback: function = undefined
    // The callback called when click on the close button
    closeCallback: undefined
  },

  onAdd: function (map) {
    const container = DomUtil.create('div', 'leaflet-close-container')

    this._closeButton = this._createButton(this.options.title, 'btn-close', container, this._closeMap)

    this._updateDisabled()

    return container
  },

  disable: function () {
    this._disabled = true
    this._updateDisabled()
    return this
  },

  enable: function () {
    this._disabled = false
    this._updateDisabled()
    return this
  },

  _closeMap: function (e) {
    if (!this._disabled) {
      if (typeof this.options.closeCallback === 'function') {
        this.options.closeCallback.call(e)
      }
    }
  },

  _createButton: function (title, className, container, fn) {
    const link = DomUtil.create('a', className, container)
    link.href = '#'
    link.title = title

    /*
     * Will force screen readers like VoiceOver to read this as "Close map - button"
     */
    link.setAttribute('role', 'button')
    link.setAttribute('aria-label', title)

    DomEvent.disableClickPropagation(link)
    DomEvent.on(link, 'click', DomEvent.stop)
    DomEvent.on(link, 'click', fn, this)

    return link
  },

  _updateDisabled: function () {
    const map = this._map
    const className = 'leaflet-disabled'

    DomUtil.removeClass(this._closeButton, className)

    if (this._disabled) {
      DomUtil.addClass(this._closeButton, className)
    }
  }
})

// @namespace Map
// @section Control options
// @option closeMapControl: Boolean = false
// Whether a [closeMap control](#control-closeMap) is added to the map.
Map.mergeOptions({
  closeMapControl: true
})

Map.addInitHook(function () {
  if (this.options.closeMapControl) {
    // @section Controls
    // @property closeMapControl: Control.CloseMap
    // The default closeMap control (only available if the
    // [`closeMapControl` option](#map-closeMapcontrol) was `true` when creating the map).
    this.closeMapControl = new CloseMap()
    this.addControl(this.closeMapControl)
  }
})

// @namespace Control.CloseMap
// @factory L.control.closemap(options: Control.CloseMap options)
// Creates a closeMap control
export function closemap(options) {
  return new CloseMap(options)
}
