import Handlebars from 'handlebars'
import { defaultTemplateRoute } from './defaultTemplateRoute.js'

export const Templates = {
  templateCache: {},
  initialized: false,

  defaultTemplateRoute,

  initialize: function () {
    this.templateCache = {}

    Handlebars.registerHelper('case', function (value, options) {
      this._switch_value_ = value
      const html = options.fn(this)
      delete this._switch_value_
      return html
    })

    Handlebars.registerHelper('when', function (value, options) {
      if (value === this._switch_value_) {
        return options.fn(this)
      }
    })
  },

  compile: function (template, helpers) {
    if (!this.initialized) {
      this.initialize()
    }

    if (!this.templateCache[template]) {
      this.templateCache[template] = Handlebars.compile(template, {
        knownHelpers: helpers
      })
    }
    return this.templateCache[template]
  }
}
