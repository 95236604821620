export const defaultTemplateRoute = `
	<div>
		<h1>Percorso</h1>
		<p>Questo &egrave; il percorso:</p>
		{{#each alternatives}}		
			<div data-alt="{{@index}}">
				PERCORSO {{@index}}
				<ul>
				{{#each rplan}}
					{{#each rleg}}
					<li data-lat="{{y}}" data-lon="{{x}}">
						{{@index}}.
						{{#case dir}}
							{{#when 0}}Continua in{{/when}}
							{{#when 1}}Gira a sinistra{{/when}}
							{{#when 2}}Gira a destra{{/when}}
							{{#when 3}}Subito a sinistra{{/when}}
							{{#when 4}}Subito a destra{{/when}}
							{{#when 5}}Esci in{{/when}}
							{{#when 6}}Immetiti{{/when}}
						{{/case}}
						{{dsc}}
					</li>
					{{/each}}
				{{/each}}
				</ul>
			</div>
		{{/each}}						
	</div>
`
