//http://mdr-routing.paginegialle.it/route_px?x=0&y=0&z=0&lx=9.19405&ly=45.46342&ex=9.15345&ey=45.49459&itn=&rt=c&rm=undefined&mdf=undefined&traffic=no&serv=&sito=&chk=&mode=tc&idx=&zone=&coordsonly=no&date=&vpt=ll&format=json&alt=2
export async function fetchRouteData(routeParams) {
  const url = buildRouteUrl(routeParams)

  return fetch(url)
    .then((response) => {
      if (!response.msg === 'OK') {
        throw new Error('Errore nella chiamata al servizio di routing')
      }
      return response.json()
    })
    .catch((error) => {
      console.error('Errore:', error)
      throw error
    })
}

/**
 * Pass in an array of JSON objects in the format "{lon:lon, lat:lat}".
 * Return a string of the values like this: "Bx;By;Cx;Cy;Dx;Dy".
 */
function getItnVal(itn) {
  let s = ''
  let i, l
  for (i = 0, l = itn.length; i < l; i++) {
    s += itn[i].lon + ';' + itn[i].lat + ';'
  }
  return s
}

export const ConstantRoute = {}

// Proprietà di ConstantRoute
const properties = {
  PGROUTE_URL: 'http://mdr-routing.paginegialle.it/route_px',
  PGROUTE_TYPE_CAR: 'c',
  PGROUTE_TYPE_PED: 'p',
  PGROUTE_TYPE_CAR_BEST_DIST: 'cbd',
  PGROUTE_TYPE_CAR_NO_TOLL: 'cnt',
  PGROUTE_TYPE_CAR_NO_ECOPASS: 'nep',
  PGROUTE_TYPE_PUBLIC_TRANSPORT: 'pt',
  PGROUTE_TYPE_PUBLIC_TRANSPORT_ALLTRANSPORTS: 'atq',
  PGROUTE_TYPE_PUBLIC_TRANSPORT_FEWTRANSFERS: 'atft',
  PGROUTE_TYPE_PUBLIC_TRANSPORT_FEWWALKS: 'atfp',
  PGROUTE_TYPE_PUBLIC_TRANSPORT_NOMETRO: 'nmq',
  PGROUTE_TYPE_PUBLIC_TRANSPORT_NOMETRO_FEWTRANSFERS: 'nmft',
  PGROUTE_TYPE_PUBLIC_TRANSPORT_NOMETRO_FEWWALKS: 'nmfp',

  PGROUTE_TRANS_PED: 0, // Pedestrian
  PGROUTE_TRANS_CAR: 1, // Car
  PGROUTE_TRANS_TRAM: 2, // Tram, Streetcar, Light rail. Any light rail or street level system within a metropolitan area.
  PGROUTE_TRANS_METRO: 3, // Subway, Metro. Any underground rail system within a metropolitan area.
  PGROUTE_TRANS_RAIL: 4, // Rail. Used for intercity or long-distance travel.
  PGROUTE_TRANS_BUS: 5, // Bus. Used for short- and long-distance bus routes.
  PGROUTE_TRANS_FERRY: 6, // Ferry. Used for short- and long-distance boat service.
  PGROUTE_TRANS_CABLE_CAR: 7, // Cable car. Used for street-level cable cars where the cable runs beneath the car.
  PGROUTE_TRANS_SUSP_CABLE_CAR: 8, // Gondola, Suspended cable car. Typically used for aerial cable cars where the car is suspended from the cable.
  PGROUTE_TRANS_FUNICULAR: 9, // Funicular. Any rail system designed for steep inclines.

  // Costanti legate al percorso testuale
  PGROUTE_COLOR: 'blue',
  PGROUTE_OPACITY_HIGH: 1,
  PGROUTE_OPACITY_MEDIUM: 0.8,
  PGROUTE_OPACITY_LOW: 0.3,
  PGRUOTE_LINE_WIDTH: '3',
  PGRUOTE_SPINNER: '<div class="spinner_pgroute"></div>',

  // Costanti tipi errori
  PGROUTE_ERROR_TYPE_NOT_CALCULABLE: 1,
  PGROUTE_ERROR_TYPE_FAILED: 2
}

// Le proprietà di ConstantRoute vengono settate in sola lettura
for (const [key, value] of Object.entries(properties)) {
  Object.defineProperty(ConstantRoute, key, {
    value,
    writable: false
  })
}

export function buildRouteUrl(routeParams, typeCall = 'json') {
  //URL construction
  const npoints = routeParams.PGPoints.length

  if (npoints < 2) {
    const error = new Error('Un percorso per essere calcolato deve avere almeno un punto di partenza ed uno di arrivo!')
    error.type = ConstantRoute.PGROUTE_ERROR_TYPE_NOT_CALCULABLE
    throw error
  }

  const startLon = routeParams.PGPoints[0].lon
  const startLat = routeParams.PGPoints[0].lat
  const endLon = routeParams.PGPoints[npoints - 1].lon
  const endLat = routeParams.PGPoints[npoints - 1].lat

  let itn = ''
  let ex = ''
  let pars = ''

  // punti intermedi
  if (npoints > 2) {
    itn = getItnVal(routeParams.PGPoints.slice(1, -1))
  }

  if (startLon === endLon && startLat === endLat && itn.length === 0) {
    const error = new Error('Un percorso con punto di partenza e di arrivo coincidenti senza tappe intermedie non può essere calcolato!')
    error.type = ConstantRoute.PGROUTE_ERROR_TYPE_NOT_CALCULABLE
    throw error
  }

  // @todo extra?
  if (routeParams.extra) {
    let k
    for (k in routeParams.extra) {
      const v = routeParams.extra[k]
      ex += '&' + k + '=' + encodeURIComponent(v)
    }
  }

  // Lon of visible map center
  // ?		pars += 'x=' + his.routeParams.lon ;
  pars += 'x=0'
  // Lat of visible map center
  // ?		pars += '&y=' + his.routeParams.lat;
  pars += '&y=0'
  // Zoom
  // ?		pars += '&z=' + his.routeParams.z;
  pars += '&z=0'
  // Route Lon start pos
  pars += '&lx=' + startLon
  // Route Lat start pos
  pars += '&ly=' + startLat
  // Route Lon end pos
  pars += '&ex=' + endLon
  // Route Lat end pos
  pars += '&ey=' + endLat
  // Route intermediate points (optional)
  pars += '&itn=' + itn
  // Route type ('c'=Car, 'c'=Pedestrian, 'trk'=Mezzi pesanti, ?-> 'CBN'=Car Best Distance 'CNT'=Car No)
  // Toll)
  pars += '&rt=' + routeParams.rt
  // Path type ('f'=Fastest, 's'=Shortest)
  pars += '&rm=' + (routeParams.pt || 'f')
  // Modifiers like notoll and noztl
  pars += '&mdf=ra;meta;wp;lc;ss;sd;tp;saxy;sv;autovelox;' + (routeParams.mdf || '')
  // traffic mode
  pars += '&traffic=' + (routeParams.traffic || 'no')
  // Width of route area (pixels)
  // ?		pars += '&xpix=' + this.routeParams.xpix;
  // Height of route area (pixels)
  // ?		pars += '&ypix=' + this.routeParams.ypix;
  // ?		pars += '&dz=' + (parseInt(this.routeParams.xpix, 10) / 430);
  // If the name/number of the server exists then pass it
  pars += '&serv=' + (routeParams.serv || '')
  // Used to identify the caller - gets passed to PGMappy during initialisation
  pars += '&sito=' + (routeParams.sito || '')
  // Check parameter which can be used to identify the origin of the call
  pars += '&chk=' + (routeParams.chk || '')
  pars += '&mode=' + (routeParams.mode || 'tc')
  // Unique identifier
  pars += '&idx=' + (routeParams.idx || '')
  // Optional UTM Zone.
  // 32: Italia
  // 33: Poland
  // 35: Turkey
  pars += '&zone=' + (routeParams.zone || '')
  // Specifies the pixel size of the textual mini maps
  pars += routeParams.minimap ? '&minimap=' + routeParams.minimap : ''
  // Don't return the textual portion
  pars += '&coordsonly=' + (routeParams.coordsonly || 'no')
  // Date requested for the route with public transports; in format RFC3339: es.
  // 2011-01-21T12:34:55
  pars += '&date=' + (routeParams.date || '')
  pars += '&vpt=ll'
  if (typeCall === 'jsonp') {
    pars += ex + '&format=javascript,' + encodeURIComponent(routeParams.callback) + (routeParams.id ? ',' + routeParams.id : '')
  } else {
    pars += ex + '&format=json'
  }

  if (typeof routeParams.alt !== 'undefined') {
    pars += '&alt=' + routeParams.alt
  } else {
    pars += '&alt=0'
  }

  const url = (routeParams.host || ConstantRoute.PGROUTE_URL) + '?' + pars

  return url
}
