export class PGGeoRevCod {
  static execute(lon, lat) {
    return new Promise((resolve, reject) => {
      // esegue la geocodifica inversa
      const url = `https://services.tuttocitta.it/reverse?x=${lon}&y=${lat}&format=javascript`

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          // elabora i dati della risposta
          const result = data
          resolve(result)
        })
        .catch((error) => {
          // gestisci l'errore
          console.log(`Errore durante la geocodifica inversa: ${error.message}`)
          reject(error)
        })
    })
  }
}
